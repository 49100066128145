<template>
    <div class="login-test">
    <div class="d-flex" id="signin" style="height: 100vh; background-image: url('/img/athens2.jpg'); flex-wrap: wrap;background-position: center;background-size: cover;">

    </div>
    <div class="section" style="height: 100vh;width: 100%; background: rgb(2,0,36);position: absolute;top: 0; background: rgb(15,0,55);background: linear-gradient(90deg, rgba(13,19,31,1) 40%, rgba(0,0,0,0) 100%);">

    </div>
    
    <div class="section hpanel rightpan">
        <div class="background-img d-flex">
         <div class="signin-form content-area " >
          <form @submit.prevent="onSubmit" class="text-white">
            <div class="d-flex justify-content-center">
           
            <div class="text-center px-2" style="width:30vh">
              <img src="img/perifereia_attica_logo.png" alt="" class="w-100" >
            </div>
    
            </div>
    
            <h3 class="text-center pt-5">Σύνδεση</h3>
    
            <div class="form-group text-start">
              <label>Username:</label>
              <input  type="text"
                      id="email"
                      v-model="username"  class="form-control form-control-lg" />
            </div>
    
            <div class="form-group text-start">
              <label >Password:</label>
              <input type="password"
                     id="password"
                     v-model="password" class="form-control form-control-lg" />
            </div>
            
    
          <div class="d-grid gap-2">
            <button type="submit" class="btn btn-outline-light btn-lg mt-3 btn-block" style="color:weight; border: 2px solid weight;">Σύνδεση</button>
          </div>
          <div class="d-grid gap-2">
            <a class="btn btn-primary btn-sm mt-3 btn-block" href="https://pka.attica.gov.gr/access-form/" target="_blank" style="color:weight; border: 2px solid weight;" >Αίτηση πρόσβασης</a>
          </div>
          <!--<div class="d-flex justify-content-center pt-4 align-items-center">
          <div class="text-center px-2" style="width:11vh">
              <img src="img/logo.png" alt="" class="w-100">
          </div>
          <div class="text-center px-2" style="width:14vh">
              <img src="img/iadet-logo.png" alt="" class="w-100">
          </div>
          <div class="text-center px-2" style="width:11vh">
              <img src="img/noa-logo.png" alt="" class="w-100">
          </div>
          </div>-->
          </form>
        
        </div>
        </div>
        
    </div>
    
    </div>
    </template>
    
    <script>
      //import store from "../../store";
      //import routes from '../router/index.js'
      export default {
        data () {
          return {
            username: '',
            password: '',
            selected_languege:'gr'
          }
        },
        methods: {
          onSubmit () {
            const formData = {
              username: this.username,
              password: this.password,
            }
            //console.log(formData)
            //localStorage.setItem('username', this.username)
            //this.$store.dispatch('login', {username: formData.username, password: formData.password})
    
              this.$store.dispatch('login', {username: formData.username, password: formData.password})
              //routes.push({ path: '/dashboard' }
            /*
            if (store.getters.isAuthenticated) {
              console.log('heredashboard')
              this.$router.push('dashboard')
            } else {
              console.log('heresignin')
              console.log(store.getters.isAuthenticated)
              this.$router.push('signin')
            }
            */
          },
          changeLang(){
            this.$store.commit('set', ['languege', this.selected_languege])
    
          }
    
    
        },
        created(){
          this.$store.commit('set', ['languege', 'gr'])
    
        }
      }
    </script>
    
    <style >
    .button:hover {
      color: white;
    }
    
      .signin-form {
        width: 370px;
        margin: auto;
        /*border: 1px solid #eee;*/
        padding: 20px;
        /*box-shadow: 0 2px 3px #ccc;*/
      
      }
    
    
    .hpanel {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff
    }
    
    .rightpan {
        
        width: 40%;
     
    }
  
    .background-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
   
    
    
    
    
    body{
      
        line-height: 1.6;
        font-weight: 100;
        font-family: 'Poppins', sans-serif;
        color: #555555;
        overflow-x: hidden;
    }
    
    .noscroll {
        overflow: hidden
    }
    @media only screen and (max-width: 1092px) {
      .rightpan {
            right: 0%;
        width: 100%;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
    }
    .rightpan .content-area {-webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
      }
    .rightpan .background-img {
        background:rgba(0, 0, 0, 0.7)
    }
    
    }
    
    </style>
    